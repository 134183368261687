<template>
   <div
      class="loader"
      :class="{ visible: LOADER }"
   >
      <div class="lds-dual-ring"></div>
   </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
   name: "BaseLoader",
   components: {},
   props: {},
   data() {
      return {};
   },
   computed: {
      ...mapGetters(["LOADER"]),
   },
   methods: {},
   watch: {},
   mounted() {},
   created() {},
};
</script>

<style lang="scss">
.loader {
   user-select: none;
   pointer-events: none;
   position: fixed;
   z-index: 101;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   width: 100vw;
   height: 100vh;
   background-color: rgba(0, 0, 0, 0.1);
   opacity: 0;
   transition: all 0.3s ease;
   &.visible {
      opacity: 1;
      user-select: initial;
      pointer-events: all;
      // cursor: wait;
      cursor: progress;
   }
   .lds-dual-ring {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: inline-block;
      width: 80px;
      height: 80px;
   }
   .lds-dual-ring:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      border: 6px solid $decor;
      border-color: $decor transparent $decor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
   }
   @keyframes lds-dual-ring {
      0% {
         transform: rotate(0deg);
      }
      100% {
         transform: rotate(360deg);
      }
   }
}
</style>
