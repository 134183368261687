export default {
   SET_SAFARI_MOBILE_TO_TRUE: (state) => {
      state.isSafariMobile = true;
   },
   SET_LOADER_TO_TRUE: (state) => {
      state.isLoader = true;
   },
   SET_LOADER_TO_FALSE: (state) => {
      state.isLoader = false;
   },
   SET_LANGUAGE: (state, lang) => {
      state.locale = lang;
      localStorage.setItem("locale", lang);
   },
};
