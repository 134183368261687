import axios from "axios";
export default {
   SEND_MEDIA({ commit }, { ...args }) {
      return axios(`${this.getters.API}${args.src}`, {
         method: "POST",
         headers: {
            "Content-Type": "multipart/form-data; boundary=<calculated when request is sent>",
            authorization: this.getters.TOKEN_A,
         },
         data: args.data,
      })
         .then((response) => {
            console.log(response);
            if (response.status == 200) {
               if (args.mutation) commit(args.mutation, response.data);
            } else {
               console.log(response);
               alert(response.statusText);
            }
            return response;
         })
         .catch((error) => {
            console.log(error);
            return error;
         });
   },
   API_ADMIN({ commit }, { ...args }) {
      let headers = {};
      if (args.method == "POST" || args.method == "PUT") {
         headers["Accept"] = "application/json";
         headers["Content-Type"] = "application/json";
      } else {
         headers["Content-Type"] = "application/x-www-form-urlencoded";
      }

      return axios(`${this.getters.API}${args.src}`, {
         method: args.method,
         headers: {
            ...headers,
            authorization: this.getters.TOKEN_A,
         },
         data: args.data ? args.data : "",
      })
         .then((response) => {
            console.log(response);
            if (response.status == 200) {
               if (args.mutation) commit(args.mutation, response.data);
            } else {
               console.log(response);
               alert(response.statusText);
            }
            return response;
         })
         .catch((error) => {
            console.log(error);
            return error;
         });
   },
   API_USER({ commit }, { ...args }) {
      let headers = {};
      if (args.method == "POST" || args.method == "PUT") {
         headers["Accept"] = "application/json";
         headers["Content-Type"] = "application/json";
      } else {
         headers["Content-Type"] = "application/x-www-form-urlencoded";
      }

      return axios(`${this.getters.API}${args.src}`, {
         method: args.method,
         headers: {
            ...headers,
         },
         data: args.data ? args.data : "",
      })
         .then((response) => {
            console.log(response);
            if (response.status == 200) {
               if (args.mutation) commit(args.mutation, response.data);
               // if (args.mutation && response.data.length) commit(args.mutation, response.data);
            } else {
               console.log(response);
               alert(response.statusText);
            }
            return response;
         })
         .catch((error) => {
            console.log(error);
            return error;
         });
   },
};
