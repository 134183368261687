<template>
   <header
      class="header"
      ref="header"
   >
      <div
         class="header__top header-top"
         ref="header-top"
      >
         <div class="header-top__container">
            <!-- <button @click="changeLang">{{ $t("navigation.lang") }}</button> -->
            <SocialIcons />
         </div>
      </div>
      <div class="header__bottom header-bottom">
         <div
            class="header-bottom__container"
            ref="header-bottom"
         >
            <div
               class="header-bottom__burger burger"
               @click="showMenu"
            >
               <div class="burger__wrapper">
                  <span></span>
               </div>
            </div>
            <div class="header-bottom__search">
               <button @click="changeLang">{{ $t("navigation.lang") }}</button>
               <!-- <svg
                  aria-hidden="true"
                  viewBox="0 0 64 64"
               >
                  <path
                     stroke="#fff"
                     stroke-width="2"
                     d="M47.16 28.58A18.58 18.58 0 1 1 28.58 10a18.58 18.58 0 0 1 18.58 18.58zM54 54L41.94 42"
                  ></path>
               </svg> -->
            </div>
            <nav class="header-bottom__navigation">
               <div class="header-bottom__list-wrapper">
                  <ul class="header-bottom__list">
                     <!-- <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'admin-products' }"> Admin page </router-link>
                     </li> -->
                     <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'home' }"> {{ $t("navigation.home") }} </router-link>
                     </li>
                     <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'products' }"> {{ $t("navigation.products") }} </router-link>
                     </li>
                     <!-- <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'about' }"> {{ $t("navigation.story") }} </router-link>
                     </li> -->
                     <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'collections' }"> {{ $t("navigation.collections") }} </router-link>
                     </li>
                  </ul>
               </div>
            </nav>
            <div
               class="header-bottom__logo"
               ref="logo"
            >
               <router-link :to="{ name: 'home' }">
                  <img :src="require(`../../assets/img/webp/logo.webp`)" />
               </router-link>
               <!-- <div class="header-bottom__trademark">{{ $t("trademark") }}</div> -->
            </div>
            <nav class="header-bottom__navigation">
               <div class="header-bottom__list-wrapper">
                  <ul class="header-bottom__list">
                     <!-- <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'collections' }"> {{ $t("navigation.collections") }} </router-link>
                     </li> -->
                     <!-- <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'about' }"> {{ $t("navigation.story") }} </router-link>
                     </li> -->

                     <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'faq' }"> {{ $t("navigation.faq") }} </router-link>
                     </li>
                     <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'feedback' }"> {{ $t("navigation.contacts") }} </router-link>
                     </li>
                     <li class="header-bottom__list-item">
                        <router-link :to="{ name: 'guarantee' }"> {{ $t("navigation.guarantee") }} </router-link>
                     </li>
                  </ul>
               </div>
            </nav>
            <div class="header-bottom__actions">
               <div class="header-bottom__search">
                  <!-- <svg
                     aria-hidden="true"
                     viewBox="0 0 64 64"
                  >
                     <path
                        stroke="#fff"
                        stroke-width="2"
                        d="M47.16 28.58A18.58 18.58 0 1 1 28.58 10a18.58 18.58 0 0 1 18.58 18.58zM54 54L41.94 42"
                     ></path>
                  </svg> -->
               </div>
               <div
                  class="header-bottom__cart"
                  @click="openCart"
               >
                  <span v-if="CART.length">{{ CART.length }}</span>
                  <svg
                     aria-hidden="true"
                     viewBox="0 0 64 64"
                  >
                     <g
                        fill="none"
                        stroke="#fff"
                        stroke-width="2"
                     >
                        <path d="M25 26c0-15.79 3.57-20 8-20s8 4.21 8 20"></path>
                        <path d="M14.74 18h36.51l3.59 36.73h-43.7z"></path>
                     </g>
                  </svg>
               </div>
            </div>
         </div>
      </div>
      <div class="header__trademark">
         {{ $t("trademark") }}
      </div>

      <div
         class="header__menu menu"
         :class="{ active: IS_HEADER_MENU_ACTIVE }"
         ref="close"
         @click="closeMenu"
      >
         <div class="menu__wrapper">
            <div class="menu__body">
               <div class="menu__top">
                  <button @click="changeLang">{{ $t("navigation.lang") }}</button>
                  <div
                     class="menu__close-icon"
                     @click.stop="closeMenu"
                  >
                     <div class="menu__close-icon-container"></div>
                  </div>
               </div>
               <nav class="menu__navigation">
                  <ul class="menu__list">
                     <li class="menu__list-item">
                        <router-link :to="{ name: 'home' }"> {{ $t("navigation.home") }} </router-link>
                     </li>
                     <li class="menu__list-item">
                        <router-link :to="{ name: 'products' }"> {{ $t("navigation.products") }} </router-link>
                     </li>
                     <li class="menu__list-item">
                        <router-link :to="{ name: 'collections' }"> {{ $t("navigation.collections") }} </router-link>
                     </li>
                     <li class="menu__list-item">
                        <router-link :to="{ name: 'faq' }"> {{ $t("navigation.faq") }} </router-link>
                     </li>
                     <!-- <li class="menu__list-item">
                        <router-link :to="{ name: 'about' }"> {{ $t("navigation.story") }} </router-link>
                     </li> -->
                     <li class="menu__list-item">
                        <router-link :to="{ name: 'feedback' }"> {{ $t("navigation.contacts") }} </router-link>
                     </li>
                     <li class="menu__list-item">
                        <router-link :to="{ name: 'guarantee' }"> {{ $t("navigation.guarantee") }} </router-link>
                     </li>
                  </ul>
               </nav>
               <SocialIcons />
            </div>
         </div>
      </div>
   </header>
</template>

<script>
import SocialIcons from "@/components/UI/SocialIcons.vue";
import BaseButton from "@/components/UI/BaseButton.vue";
import { mapGetters, mapActions } from "vuex";
export default {
   name: "BaseHeader",
   components: { SocialIcons, BaseButton },
   props: {},
   data() {
      return {};
   },
   computed: {
      ...mapGetters([
         "LOCALE",
         "IS_HEADER_MENU_ACTIVE",
         "CART",
         // NAME
      ]),
   },
   methods: {
      ...mapActions([
         "SHOW_LOADER",
         "HIDE_LOADER",
         "CHANGE_LANGUAGE",
         "SHOW_LOADER",
         "HIDE_LOADER",
         // NAME
      ]),
      toggleLoader() {
         this.SHOW_LOADER();
         setTimeout(() => {
            this.HIDE_LOADER();
         }, 1000);
      },
      changeLang() {
         if (this.$i18n.locale === "ua") {
            this.CHANGE_LANGUAGE("en");
         } else {
            this.CHANGE_LANGUAGE("ua");
         }
         this.$store.state.isHeaderMenuActive = false;
      },
      showMenu() {
         this.$store.state.isHeaderMenuActive = true;
      },
      closeMenu(e) {
         if (e.target === this.$refs.close || e.target.closest(".menu__close-icon")) {
            this.$store.state.isHeaderMenuActive = false;
         }
      },
      openCart() {
         this.$store.state.isCartActive = true;
      },
      toggleHeaderBlock() {
         if (window.scrollY < this.$refs.header.scrollHeight * 1.7) {
            if (this.$refs.header.classList.contains("increased")) {
               this.$refs.header.classList.remove("increased");
               this.$refs["header-top"].style.height = this.$refs["header-top"].scrollHeight + "px";
               // this.$refs.header.addEventListener("transitionend", this.appPadding, { once: true });
            }
         } else if (window.scrollY > this.$refs.header.scrollHeight * 1.2) {
            if (!this.$refs.header.classList.contains("increased")) {
               this.$refs.header.classList.add("increased");
               this.$refs["header-top"].style.height = 0;
            }
         }
      },
      appPadding() {
         if (this.$refs.header) {
            document.getElementById("app").style.paddingTop = this.$refs.header.scrollHeight - 1 + "px";
         }
      },
   },
   watch: {
      $route() {
         document.body.classList.remove("lock");
         this.appPadding();
      },
      IS_HEADER_MENU_ACTIVE() {
         if (this.IS_HEADER_MENU_ACTIVE) {
            document.body.classList.add("lock");
            this.$refs.header.style.opacity = "1";
         } else {
            document.body.classList.remove("lock");
            if (window.scrollY > this.$refs.header.scrollHeight * 1.2) {
               this.$refs.header.style.opacity = "0.95";
            }
         }
      },
   },
   created() {},
   beforeMount() {},
   mounted() {
      this.appPadding();
      setTimeout(() => {
         this.appPadding();
      }, 100);
      this.toggleHeaderBlock();
      window.addEventListener("scroll", this.toggleHeaderBlock);
      window.addEventListener("orientationchange", this.appPadding);
   },
   beforeUnmount() {
      window.removeEventListener("scroll", this.toggleHeaderBlock);
      window.removeEventListener("orientationchange", this.appPadding);
      document.getElementById("app").style.paddingTop = 0;
   },
   unmounted() {},
   beforeUpdate() {},
   updated() {},
};
</script>

<style lang="scss">
.header {
   position: fixed;
   z-index: 10;
   top: 0;
   left: 0;
   width: 100%;
   background-color: $dark;
   // todo ClassList
   &.increased {
      opacity: 0.95;
      .header-top {
         height: 0;
      }
      .header-bottom {
         position: relative;
         @media (max-width: 989px) {
            &__container {
               padding-top: 5px;
               padding-bottom: 5px;
            }
            &__logo {
               max-width: 40px;
            }
         }
         @media (min-width: 990px) {
            &__container {
               padding-top: 15px;
               padding-bottom: 15px;
            }
            &__logo {
               max-width: 70px;
            }
         }
      }
      .header__trademark {
         @media (max-width: 989px) {
            margin-top: 0;
         }
         @media (min-width: 990px) {
            margin-top: -10px;
         }
      }
   }
   // .header__container

   &__container {
      @extend %container;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 30px;
   }
   &__trademark {
      @extend %container;

      margin-top: -5px;
      @media (max-width: 567px) {
         font-size: 0.7em;
      }
      @media (min-width: 990px) {
         margin-top: -15px;
      }
      text-align: center;
      color: #fff;
      padding-bottom: 10px;
   }
}
.header-top {
   @media (max-width: 989px) {
      display: none;
   }
   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
   overflow: hidden;
   transition: all 0.3s ease;

   // .header-top__container
   &__container {
      padding-top: 16px;
      padding-bottom: 4px;
      @extend %container;
      display: flex;
      justify-content: flex-end;
      column-gap: 20px;
      button {
         color: $white;
      }
   }
}
.header-bottom {
   position: relative;
   // .header-bottom__container

   &__container {
      transition: all 0.3s ease;
      @extend %container;
      color: $white;
      svg {
         @media (max-width: 989px) {
            max-width: 25px;
         }
         @media (min-width: 990px) {
            max-width: 30px;
         }
         width: 100%;
         cursor: pointer;
         padding: 5px;
      }
      @media (max-width: 989px) {
         padding-top: 15px;
         padding-bottom: 15px;
         display: grid;
         grid-template-columns: 1fr 1fr 1fr;
         align-items: center;
         column-gap: 10px;
         > :first-child {
            justify-self: start;
         }
         > :last-child {
            justify-self: end;
         }
         .header-bottom__logo {
            justify-self: center;
         }
      }
      @media (min-width: 990px) {
         padding-top: 30px;
         padding-bottom: 30px;
         display: grid;
         align-items: center;
         justify-items: center;
         grid-template-columns: 1fr 6fr 2fr 6fr 1fr;
         column-gap: 20px;
         > :first-child,
         :nth-child(2) {
            justify-self: start;
         }
         > :last-child {
            justify-self: end;
         }
      }
   }

   // .header-bottom__search

   &__search {
      @media (max-width: 989px) {
         &:not(:first-child) {
            display: none;
         }
      }
   }

   // .header-bottom__navigation

   &__navigation {
      @media (max-width: 989px) {
         display: none;
      }
      @media (min-width: 990px) {
         &:first-of-type {
            justify-self: end;
         }
         &:last-of-type {
            justify-self: start;
         }
      }
   }

   // .header-bottom__list-wrapper

   &__list-wrapper {
      display: flex;
      justify-content: flex-start;
   }
   // .header-bottom__list

   &__list {
      @media (min-width: 990px) {
         display: flex;
         justify-content: center;
         align-items: center;
         flex-wrap: wrap;
         column-gap: 10px;
         row-gap: 24px;
      }
   }

   // .header-bottom__list-item

   &__list-item {
      @media (min-width: 990px) {
         text-align: center;
         a {
            text-transform: uppercase !important;
            padding: 10px;
            position: relative;
            &:hover {
               &:after {
                  width: calc(100% - 16px);
               }
            }
            &:after {
               transition: all 0.5s ease;
               content: "";
               position: absolute;
               bottom: 0;
               left: 8px;
               right: 8px;
               width: 0;
               height: 2px;
               background: $white;
            }
            &.router-link-exact-active {
               opacity: 0.8;
               user-select: none;
               pointer-events: none;
            }
            // &.router-link-exact-active {
            //    cursor: default;
            //    pointer-events: none;
            //    &:after {
            //       width: calc(100% - 16px);
            //    }
            // }
         }
      }
   }

   // .header-bottom__logo

   &__logo {
      width: 100%;
      transition: all 0.3s ease;

      @media (max-width: 989px) {
         max-width: 70px;
      }
      @media (min-width: 990px) {
         max-width: 100px;
      }
   }

   // &__trademark {
   //    position: absolute;
   //    left: 50%;
   //    bottom: 2px;
   //    transform: translate(-50%, -50%);
   // }

   // .header-bottom__actions

   &__actions {
      @media (max-width: 989px) {
         display: flex;
         align-items: center;
         // column-gap: 10px;
      }
      @media (min-width: 990px) {
         > :first-child {
            display: none;
         }
      }
   }

   // .header-bottom__cart

   &__cart {
      position: relative;
      span {
         display: flex;
         justify-content: center;
         align-items: center;
         @media (min-width: 990px) {
            @include rem(12);
            bottom: 10%;
            right: 10%;
            width: 16px;
            height: 16px;
         }
         @media (max-width: 989px) {
            @include rem(8.5);
            bottom: 15%;
            right: 15%;
            width: 13px;
            height: 13px;
         }
         font-weight: 700;
         font-family: $ff2;
         line-height: 1;
         position: absolute;
         border-radius: 50%;
         background-color: $decor;
         border: 1.5px solid $white;
      }
      @media (min-width: 990px) {
         transition: all 0.5s ease;
         &:hover {
            transform: scale(1.15);
         }
      }
   }
}
// .burger
.burger {
   @media (min-width: 990px) {
      display: none;
   }
   @media (max-width: 989px) {
      position: relative;
      z-index: 2;
      min-height: 40px; // Высота блока для удобства нажатия на иконку
      width: 40px; // Ширина блока для удобства нажатия на иконку
      // ? Двигаем на половину от разницы между width для .burger и width для .burger__wrapper что бы .burger прилип к контейнеру
      // margin-left: -5px;
      // ? Двигаем на половину от разницы между width для .burger и width для .burger__wrapper что бы .burger прилип к контейнеру
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease;
      cursor: pointer;
      &__wrapper {
         width: 30px; // todo Макетная ширина бургера
         height: 20px; // todo Макетная высота бургера
         position: relative;
         display: flex;
         flex-direction: column;
         span,
         &:before,
         &:after {
            height: 2px;
            background-color: $white;
            transition: all 0.3s ease;
            transform: translateY(0);
            position: relative;
            left: 0;
         }
         &:before {
            content: "";
            width: 100%;
            top: 0;
            transform: translateY(0);
         }
         &:after {
            content: "";
            width: 100%;
            top: 100%;
            transform: translateY(-300%);
         }
         span {
            width: 80%;
            top: 50%;
            transform: translateY(-150%);
         }
      }
   }
}
.menu {
   position: fixed;
   pointer-events: none;
   user-select: none;
   z-index: 0;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   min-height: 100vh;
   min-width: 100vw;
   background-color: rgba(255, 255, 255, 0);
   transition: background-color 0.5s ease;
   &.active {
      z-index: 11;
      pointer-events: initial;
      user-select: initial;
      background-color: rgba(255, 255, 255, 0.5);
      .menu__wrapper {
         transform: translateX(0);
      }
   }
   // .menu__wrapper

   &__wrapper {
      min-width: 280px;
      background-color: $white;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      transition: transform 0.5s ease;
   }

   // .menu__body

   &__body {
      padding: 20px;
      display: grid;
      row-gap: 20px;
   }

   // .menu__top

   &__top {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      column-gap: 20px;
      margin-bottom: 4px;
   }
   // .menu__close-icon

   &__close-icon {
      justify-self: end;
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
   }
   // .menu__close-icon-container

   &__close-icon-container {
      width: 20px;
      height: 20px;
      position: relative;
      &:after,
      &:before {
         content: "";
         position: absolute;
         top: 50%;
         left: 0;
         transform: translateY(-50%);
         width: 100%;
         height: 2px;
         background: $black;
      }
      &:after {
         transform: rotate(45deg);
      }
      &:before {
         transform: rotate(-45deg);
      }
   }
   // .menu__navigation

   &__navigation {
   }

   // .menu__list

   &__list {
   }

   // .menu__list-item

   &__list-item {
      a {
         text-transform: uppercase;
         display: block;
         padding-top: 15px;
         padding-bottom: 15px;
         border-top: 1px solid $grey;
         @extend %space1;
      }
   }
   .social {
      margin-bottom: 100px;
      svg {
         max-width: 24px;
         max-height: 24px;
      }
      path {
         fill: $dark;
      }
   }
}
</style>
