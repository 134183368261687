<template>
  <div class="cart-item">
    <div class="cart-item__image">
      <img
        :src="cart_item.link"
        :alt="cart_item.name"
      />
    </div>
    <div class="cart-item__body">
      <div class="cart-item__content">
        <!-- <div class="cart-item__name">{{ cart_item[`name_${LOCALE}`] }}</div> -->
        <div class="cart-item__name">{{ cart_item.name }}</div>
        <ul
          class="cart-item__constructor"
          v-if="cart_item.isConstructor"
        >
          <li
            v-for="(val, key) in cart_item.constructorData"
            :key="key"
            class="cart-item__constructor-item"
          >
            <strong>{{ key }}:</strong> {{ val }}
          </li>
        </ul>
        <div
          class="cart-item__size"
          v-if="cart_item.size"
        >
          <span>{{ $t(`info.size`) }}: </span>
          {{ cart_item.size }}
          <span>{{ $t("info.cm") }}</span>
        </div>
      </div>
      <div class="cart-item__row">
        <div class="cart-item__quantity-wrapper">
          <div
            class="cart-item__decrement"
            @click="decrementQuantity"
          >
            <svg viewBox="0 0 20 20">
              <path
                d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"
              ></path>
            </svg>
          </div>
          <div class="cart-item__quantity">{{ cart_item.quantity }}</div>
          <div
            class="cart-item__increment"
            @click="incrementQuantity"
          >
            <svg viewBox="0 0 20 20">
              <path
                d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="cart-item__price">{{ cart_item.fullPrice }} {{ $t("currency") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "CartItem",
  components: {},
  props: {
    cart_item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "LOCALE",
      // "NAME"
    ]),
  },
  methods: {
    ...mapActions([
      // "NAME",
      // "NAME"
    ]),
    decrementQuantity() {
      this.$emit("decrementQuantity");
    },
    incrementQuantity() {
      this.$emit("incrementQuantity");
    },
  },
  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  beforeUnmount() {},
  unmounted() {},
  beforeUpdate() {},
  updated() {},
};
</script>

<style lang="scss">
.cart-item {
  padding-top: 20px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 15px;
  &:not(:last-child) {
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: 1px;
      background: $dark;
    }
  }
  // .cart-item__image

  &__image {
    user-select: none;
    width: 100px;
    height: 100px;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  // .cart-item__body

  &__body {
    display: grid;
    grid-template-rows: 1fr auto;
    row-gap: 10px;
  }

  &__content {
  }

  // .cart-item__name

  &__name {
    @include fl(19, 27);
    margin-bottom: 8px;
  }

  // .cart-item__constructor

  &__constructor {
    margin-bottom: 6px;
  }
  // .cart-item__constructor-item

  &__constructor-item {
    strong {
      font-variant: 600;
    }
    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
  // .cart-item__size

  &__size {
    span {
      &:first-child {
        font-weight: 700;
      }
      &:last-child {
        font-size: 0.9em;
      }
    }
  }

  // .cart-item__row

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
  }

  // .cart-item__quantity-wrapper

  &__quantity-wrapper {
    display: flex;
    align-items: center;
    column-gap: 2px;
    border: 1px solid $darkGrey;
  }

  &__decrement,
  &__increment {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    svg {
      width: 16px;
      height: 16px;
    }
    path {
      transition: all 0.3s ease;
      fill: #444;
    }
    transition: all 0.3s ease;
    &:hover {
      background-color: $dark;
      path {
        fill: $white;
      }
    }
  }
  // .cart-item__decrement
  &__decrement {
  }

  // .cart-item__quantity

  &__quantity {
    user-select: none;
    @include rem(18);
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // .cart-item__increment

  &__increment {
  }

  // .cart-item__price

  &__price {
    user-select: none;
  }
}
</style>
