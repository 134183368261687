export default {
   SET_SAFARI_MOBILE({ commit }) {
      commit("SET_SAFARI_MOBILE_TO_TRUE");
   },
   SHOW_LOADER({ commit }) {
      commit("SET_LOADER_TO_TRUE");
   },
   HIDE_LOADER({ commit }) {
      commit("SET_LOADER_TO_FALSE");
   },
   CHANGE_LANGUAGE({ commit }, lang) {
      commit("SET_LANGUAGE", lang);
   },
};
