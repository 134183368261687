<template>
   <div class="title">
      <slot></slot>
   </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
   name: "MyTitle",
   components: {},
   props: {},
   data() {
      return {};
   },
   computed: {
      ...mapGetters([
         // "NAME",
         // "NAME"
      ]),
   },
   methods: {
      ...mapActions([
         // "NAME",
         // "NAME"
      ]),
   },
   watch: {},
   created() {},
   beforeMount() {},
   mounted() {},
   beforeUnmount() {},
   unmounted() {},
   beforeUpdate() {},
   updated() {},
};
</script>

<style lang="scss">
.title {
   font-family: $ff2;
   @media (max-width: 989px) {
      @include fl(22);
   }
   @media (min-width: 990px) {
      @include fl(30);
   }
   &_big {
      text-transform: uppercase;
   }
}
</style>
