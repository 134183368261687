import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes";
import store from "@/store/store";
const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
});

router.beforeEach((to) => {
   if (to.meta.isNeedAuth && store.state.token_a.length < 1) {
      return { name: "login" };
   }
   store.state.isHeaderMenuActive = false;
});
router.afterEach(() => {
   window.scrollTo({ top: 0, behavior: "instant" });
});

export default router;
