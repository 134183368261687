export default {
   SET_PRODUCTS_USER: (state, data) => {
      state.api_products_user = data;
   },
   SET_COLLECTIONS_USER: (state, data) => {
      state.api_collections_user = data;
   },
   SET_SUBCOLLECTIONS_USER: (state, data) => {
      state.api_subcollections_user = data;
   },
   SET_CATEGORIES_USER: (state, data) => {
      state.api_categories_user = data;
   },
   SET_GALLERIES_USER: (state, data) => {
      state.api_galleries_user = data;
   },

   SET_PRODUCTS_ADMIN: (state, data) => {
      state.api_products_admin = data;
   },
   SET_COLLECTIONS_ADMIN: (state, data) => {
      state.api_collections_admin = data;
   },
   SET_SUBCOLLECTIONS_ADMIN: (state, data) => {
      state.api_subcollections_admin = data;
   },
   SET_CATEGORIES_ADMIN: (state, data) => {
      state.api_categories_admin = data;
   },
   SET_GALLERIES_ADMIN: (state, data) => {
      state.api_galleries_admin = data;
   },
};
