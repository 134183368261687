import { createI18n } from "vue-i18n";
import text_ua from "@/i18n/text-ua.js";
import text_en from "@/i18n/text-en.js";

const i18n = createI18n({
   locale: "ua", // Установите язык по умолчанию
   messages: {
      ua: text_ua,
      en: text_en,
   },
   legacy: false,
   globalInjection: true,
});

export default i18n;
