export default {
   ADD_TO_CART({ commit }, data) {
      commit("UPDATE_CART", data);
   },
   INCREMENT_QUANTITY({ commit }, index) {
      commit("INCREMENT", index);
   },
   DECREMENT_QUANTITY({ commit }, index) {
      commit("DECREMENT", index);
   },
};
