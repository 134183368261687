export default {
   LOADER(state) {
      return state.isLoader;
   },
   IS_SAFARI_MOBILE(state) {
      return state.isSafariMobile;
   },
   IS_HEADER_MENU_ACTIVE(state) {
      return state.isHeaderMenuActive;
   },
   IS_CART_ACTIVE(state) {
      return state.isCartActive;
   },
   LOCALE(state) {
      return state.locale;
   },
   PRODUCTS(state) {
      return state.products;
   },
   PRODUCTS_EN(state) {
      return state.products_en;
   },
   UNIQUE_CATEGORIES_PRODUCTS(state) {
      return state.unique_categories_products;
   },
   UNIQUE_CATEGORIES_PRODUCTS_EN(state) {
      return state.unique_categories_products_en;
   },
   CURRENT_UNIQUE_CATEGORIES_PRODUCTS(state) {
      return state.current_unique_categories_products;
   },
   CURRENT_UNIQUE_CATEGORIES_PRODUCTS_STATIC(state) {
      return state.current_unique_categories_products_static;
   },
   CURRENT_UNIQUE_COLLECTIONS_PRODUCTS(state) {
      return state.current_unique_collections_products;
   },
   CART(state) {
      return state.cart;
   },

   API(state) {
      return state.api;
   },
   API_PRODUCTS_USER(state) {
      return state.api_products_user;
   },
   API_COLLECTIONS_USER(state) {
      return state.api_collections_user;
   },
   API_SUBCOLLECTIONS_USER(state) {
      return state.api_subcollections_user;
   },
   API_CATEGORIES_USER(state) {
      return state.api_categories_user;
   },
   API_GALLERIES_USER(state) {
      return state.api_galleries_user;
   },

   BASE_IMAGE(state) {
      return state.base_image;
   },
   BASE_IMAGE_2(state) {
      return state.base_image_2;
   },
   TOKEN_A(state) {
      return state.token_a;
   },
   API_PRODUCTS_ADMIN(state) {
      return state.api_products_admin;
   },
   API_COLLECTIONS_ADMIN(state) {
      return state.api_collections_admin;
   },
   API_SUBCOLLECTIONS_ADMIN(state) {
      return state.api_subcollections_admin;
   },
   API_CATEGORIES_ADMIN(state) {
      return state.api_categories_admin;
   },
   API_GALLERIES_ADMIN(state) {
      return state.api_galleries_admin;
   },
};
