<template>
   <BaseLoader />
   <BaseHeader v-if="shouldShowComponents && TOKEN_A" />
   <main>
      <router-view />
   </main>
   <BaseFooter v-if="shouldShowComponents && TOKEN_A" />
   <Cart />
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Cart from "@/components/cart/Cart.vue";
import BaseLoader from "@/components/UI/BaseLoader.vue";
import BaseHeader from "@/components/header/BaseHeader.vue";
import BaseFooter from "@/components/footer/BaseFooter.vue";
export default {
   name: "",
   components: {
      BaseLoader,
      BaseHeader,
      BaseFooter,
      Cart,
      //
   },
   props: {},
   data() {
      return {};
   },
   computed: {
      ...mapGetters([
         "LOCALE",
         "TOKEN_A",
         "API_PRODUCTS_USER",
         "API_CATEGORIES_USER",
         "API_COLLECTIONS_USER",
         "API_SUBCOLLECTIONS_USER",
         "API_GALLERIES_USER",
         //
      ]),
      shouldShowComponents() {
         return !this.$router.currentRoute.value.meta.hideComponents;
      },
   },
   methods: {
      ...mapActions([
         "CHANGE_LANGUAGE",
         "SET_SAFARI_MOBILE",

         "ADD_TO_CART",

         "API_USER",
         // NAME
      ]),
      detectBrowser() {
         let userAgent = navigator.userAgent.toLowerCase();
         // if (userAgent.indexOf("safari") !== -1 && userAgent.indexOf("mobile") !== -1) {
         // Проверяем, является ли браузер Safari и выполняется ли код на мобильном устройстве или планшете
         if (userAgent.indexOf("safari") === -1 && userAgent.indexOf("mobile") === -1) {
            this.SET_SAFARI_MOBILE();
         }
      },
      getData() {
         this.API_USER({
            method: "GET",
            src: "/products/enabled",
            mutation: "SET_PRODUCTS_USER",
         });
         this.API_USER({
            method: "GET",
            src: "/collections/enabled",
            mutation: "SET_COLLECTIONS_USER",
         });
         this.API_USER({
            method: "GET",
            src: `/subcollections/enabled`,
            mutation: "SET_SUBCOLLECTIONS_USER",
         });
         this.API_USER({
            method: "GET",
            src: "/categories/enabled",
            mutation: "SET_CATEGORIES_USER",
         });
         this.API_USER({
            method: "GET",
            src: "/galleries/enabled",
            mutation: "SET_GALLERIES_USER",
         });
      },
   },
   watch: {
      // Меняем CURRENT при смене языка
      LOCALE() {
         this.$i18n.locale = this.LOCALE;
      },
   },
   created() {
      this.detectBrowser();
      const lang = localStorage.getItem("locale");
      if (!lang) {
         this.$i18n.locale = "ua";
      } else if (lang === "en") {
         this.$i18n.locale = "en";
      } else {
         this.$i18n.locale = "ua";
      }
      this.CHANGE_LANGUAGE(this.$i18n.locale);
      localStorage.setItem("locale", this.$i18n.locale);
      this.getData();
      let cart = JSON.parse(localStorage.getItem("cart"));
      if (cart) {
         cart.forEach((cartItem) => {
            this.ADD_TO_CART(cartItem);
         });
      }
      let token = localStorage.getItem("token_a");
      if (token) {
         this.$store.state.token_a = token;
      }
   },
   beforeMount() {},
   mounted() {},
   beforeUnmount() {},
   unmounted() {},
   beforeUpdate() {},
   updated() {},
};
</script>
<style lang="scss">
body.lock {
   overflow: hidden;
}
@media (max-width: 767px) {
   #wfp-container {
      height: 90vh;
      width: 90vw;
      top: 2.5%;
      left: 2.5%;
   }
}
body.wfp-widget-open {
   #app {
      user-select: none;
      pointer-events: none;
      filter: blur(3px);
   }
}

#app {
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   font-family: $ff1;
   @include rem(16);
   @extend %space0;
   line-height: 1;
   > div {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
   }
   main {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      > :only-child {
         flex: 1 1 auto;
      }
   }
   img {
      max-width: 100%;
   }
}
</style>
