import { createStore } from "vuex";
// Actions
import commonActions from "@/store/actions/actions";
import apiRequestsGet from "@/store/actions/api-requests";
import productsGet from "@/store/actions/products";
const actions = {
   ...commonActions,
   ...apiRequestsGet,
   ...productsGet,
};

// Mutations
import commonMutations from "@/store/mutations/mutations";
import apiRequestsSet from "@/store/mutations/api-request";
import productsSet from "@/store/mutations/products";
const mutations = {
   ...commonMutations,
   ...apiRequestsSet,
   ...productsSet,
};

// Getters
import getters from "@/store/getters/getters";

export default createStore({
   state: {
      base_image:
         "https://firebasestorage.googleapis.com/v0/b/braselet-sender.appspot.com/o/empty_1.webp?alt=media&token=b36a5d5c-35e8-4e91-8509-7e65b9e65b9c",
      base_image_2:
         "https://firebasestorage.googleapis.com/v0/b/braselet-sender.appspot.com/o/empty_2.webp?alt=media&token=b36a5d5c-35e8-4e91-8509-7e65b9e65b9c",
      // api: "http://vs194.mirohost.net:8000",
      // api: "https://vs194.mirohost.net:8000",
      api: "https://app.api.braselet.com.ua",
      token_a: "",

      isHeaderMenuActive: false,
      isCartActive: false,

      locale: "ua",
      isSafariMobile: false,
      isLoader: false,

      cart: [],

      api_products_user: [],
      api_recommendations: [],
      api_collections_user: [],
      api_subcollections_user: [],
      api_categories_user: [],
      api_galleries_user: [],

      api_products_admin: [],
      api_collections_admin: [],
      api_subcollections_admin: [],
      api_categories_admin: [],
      api_galleries_admin: [],
   },
   mutations,
   actions,
   getters,
   modules: {},
});
