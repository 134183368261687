export default {
   UPDATE_CART: (state, data) => {
      console.log("add");

      if (!state.cart.length) {
         state.cart.push(data);
      } else {
         const indexSize = state.cart.findIndex((el) => el.size == data.size && el.article == data.article);

         if (indexSize === -1) {
            state.cart.push(data);
         } else {
            state.cart[indexSize].quantity++;
            localStorage.setItem("cart", JSON.stringify(state.cart));
            return;
         }

         const index = state.cart.findIndex((el) => el.article === data.article);
         if (indexSize === -1) {
            localStorage.setItem("cart", JSON.stringify(state.cart));
            return;
         } else if (index === -1) {
            state.cart.push(data);
         } else {
            state.cart[index].quantity++;
         }
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
   },
   INCREMENT: (state, index) => {
      state.cart[index].quantity++;
      localStorage.setItem("cart", JSON.stringify(state.cart));
   },
   DECREMENT: (state, index) => {
      if (state.cart[index].quantity == 1) {
         state.cart.splice(index, 1);
      } else {
         state.cart[index].quantity--;
      }
      localStorage.setItem("cart", JSON.stringify(state.cart));
   },
};
