<template>
   <div
      class="cart"
      :class="{ active: IS_CART_ACTIVE }"
      ref="cart"
   >
      <div class="cart__wrapper">
         <div class="cart__container">
            <div class="cart__top">
               <MyTitle class="cart__title">
                  {{ $t("cart.title") }}
               </MyTitle>
               <div class="cart__close">
                  <svg
                     aria-hidden="true"
                     viewBox="0 0 64 64"
                     ref="close-cart"
                  >
                     <path
                        stroke="black"
                        d="M19 17.61l27.12 27.13m0-27.12L19 44.74"
                     ></path>
                  </svg>
               </div>
            </div>
         </div>
         <div
            v-if="!CART.length"
            class="cart__empty cart__container"
         >
            {{ $t("cart.empty") }}
         </div>
         <template v-else>
            <div class="cart__body cart__container">
               <div class="cart__content">
                  <CartItem
                     v-for="(item, index) in CART"
                     :key="item.article"
                     :cart_item="item"
                     @incrementQuantity="incrementQuantity(index)"
                     @decrementQuantity="decrementQuantity(index)"
                  />
               </div>
            </div>
            <div class="cart__bottom cart__container">
               <div class="cart__subtotal">
                  <p>{{ $t("cart.subtotal") }}</p>
                  <p>{{ subtotal }} {{ $t("currency") }}</p>
               </div>
               <BlackButton @click="order">{{ $t("cart.checkout") }}</BlackButton>
            </div>
         </template>
      </div>
   </div>
</template>

<script>
import MyTitle from "@/components/UI/MyTitle";
import CartItem from "@/components/cart/CartItem";
import BlackButton from "@/components/UI/BlackButton";
import { mapGetters, mapActions } from "vuex";
export default {
   name: "Cart",
   components: { BlackButton, CartItem, MyTitle },
   props: {},
   data() {
      return {};
   },
   computed: {
      ...mapGetters([
         "CART",
         "IS_CART_ACTIVE",
         // "NAME"
      ]),
      subtotal() {
         if (this.CART.length) {
            let result = [];
            for (let item of this.CART) {
               if (item.discountAmount > 0) {
                  result.push(this.count(item.fullPrice, item.discountAmount) * item.quantity);
               } else {
                  result.push(item.fullPrice * item.quantity);
               }
            }
            result = result.reduce((sum, el) => {
               return sum + el;
            });

            return result;
         } else {
            return 0;
         }
      },
   },
   methods: {
      ...mapActions([
         "DECREMENT_QUANTITY",
         "INCREMENT_QUANTITY",
         // "NAME"
      ]),
      closeCart(e) {
         if (e.target === this.$refs.cart || e.target === this.$refs["close-cart"]) {
            this.$store.state.isCartActive = false;
         }
      },
      incrementQuantity(index) {
         this.INCREMENT_QUANTITY(index);
      },
      decrementQuantity(index) {
         this.DECREMENT_QUANTITY(index);
      },
      order() {
         this.$store.state.isCartActive = false;
         this.$router.push({ name: "ordering" });
      },
   },
   watch: {
      IS_CART_ACTIVE() {
         if (this.IS_CART_ACTIVE) {
            document.body.classList.add("lock");
         } else {
            document.body.classList.remove("lock");
         }
      },
   },
   created() {},
   beforeMount() {},
   mounted() {
      this.$refs.cart.addEventListener("click", this.closeCart);
      this.$refs["close-cart"].addEventListener("click", this.closeCart);
   },
   beforeUnmount() {
      this.$refs.cart.removeEventListener("click", this.closeCart);
      this.$refs["close-cart"].removeEventListener("click", this.closeCart);
   },
   unmounted() {},
   beforeUpdate() {},
   updated() {},
};
</script>

<style lang="scss">
.cart {
   position: fixed;
   z-index: 10;
   top: 0;
   right: -100vw;
   bottom: 0;
   width: 100vw;
   height: 100vh;
   pointer-events: none;
   user-select: none;
   transition: right 0.8s ease 0.3s, backdrop-filter 0.3s ease 0s;

   &.active {
      pointer-events: initial;
      user-select: initial;
      right: 0;
      backdrop-filter: blur(3px);
      transition: right 0.8s ease 0s, backdrop-filter 0.3s ease 0.8s;
   }
   // .cart__wrapper

   &__wrapper {
      position: absolute;
      top: 0;
      right: 0;
      @media (max-width: 467px) {
         width: 100%;
      }
      @media (min-width: 468px) {
         min-width: 400px;
      }
      @media (min-width: 768px) {
         min-width: 450px;
      }
      @media (max-width: 989px) {
         height: 70vh;
      }
      @media (min-width: 990px) {
         height: 100vh;
      }
      background-color: #fff;
      display: grid;
      grid-template-rows: auto 1fr auto;
   }

   // .cart__top

   &__top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;
      text-transform: uppercase;
      border-bottom: 1px solid $grey;
      padding-top: 15px;
      padding-bottom: 15px;
   }

   // .cart__container

   &__container {
      padding-left: 15px;
      padding-right: 10px;
   }

   // .cart__title

   &__title {
   }

   // .cart__close

   &__close {
      cursor: pointer;
      transition: all 0.3s ease;
      padding-left: 5px;
      padding-right: 5px;
      margin-right: -5px;
      &:hover {
         transform: scale(1.1);
      }
      svg {
         width: 40px;
         height: auto;
      }
   }

   // .cart__empty
   &__empty {
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 0.9em;
   }

   // .cart__body

   &__body {
      // padding-top: 30px;
      // padding-bottom: 30px;
   }

   // .cart__content

   &__content {
      overflow: auto;
      @media (max-width: 989px) {
         max-height: calc(70vh - 73px - 106px);
      }
      @media (min-width: 990px) {
         max-height: calc(100vh - 73px - 106px);
      }
   }

   // .cart__bottom

   &__bottom {
      border-top: 1px solid $grey;
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
   }

   // .cart__subtotal

   &__subtotal {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      column-gap: 20px;
      p {
         &:first-child {
            font-size: 0.8em;
            font-family: $ff2;
            text-transform: uppercase;
            @extend %space1;
         }
         &:last-child {
            font-weight: 700;
            font-size: 0.9em;
         }
      }
   }
   button {
      width: 100%;
      transition: all 0.3s ease;
      &:hover {
         transform: scale(1);
         opacity: 0.8;
      }
   }
}
</style>
