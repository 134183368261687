<template>
   <ul class="social">
      <li>
         <a
            href="https://instagram.com/watchbandsstore_ua?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
         >
            <svg
               aria-hidden="true"
               focusable="false"
               role="presentation"
               viewBox="0 0 32 32"
            >
               <path
                  fill="#444"
                  d="M16 3.094c4.206 0 4.7.019 6.363.094 1.538.069 2.369.325 2.925.544.738.287 1.262.625 1.813 1.175s.894 1.075 1.175 1.813c.212.556.475 1.387.544 2.925.075 1.662.094 2.156.094 6.363s-.019 4.7-.094 6.363c-.069 1.538-.325 2.369-.544 2.925-.288.738-.625 1.262-1.175 1.813s-1.075.894-1.813 1.175c-.556.212-1.387.475-2.925.544-1.663.075-2.156.094-6.363.094s-4.7-.019-6.363-.094c-1.537-.069-2.369-.325-2.925-.544-.737-.288-1.263-.625-1.813-1.175s-.894-1.075-1.175-1.813c-.212-.556-.475-1.387-.544-2.925-.075-1.663-.094-2.156-.094-6.363s.019-4.7.094-6.363c.069-1.537.325-2.369.544-2.925.287-.737.625-1.263 1.175-1.813s1.075-.894 1.813-1.175c.556-.212 1.388-.475 2.925-.544 1.662-.081 2.156-.094 6.363-.094zm0-2.838c-4.275 0-4.813.019-6.494.094-1.675.075-2.819.344-3.819.731-1.037.4-1.913.944-2.788 1.819S1.486 4.656 1.08 5.688c-.387 1-.656 2.144-.731 3.825-.075 1.675-.094 2.213-.094 6.488s.019 4.813.094 6.494c.075 1.675.344 2.819.731 3.825.4 1.038.944 1.913 1.819 2.788s1.756 1.413 2.788 1.819c1 .387 2.144.656 3.825.731s2.213.094 6.494.094 4.813-.019 6.494-.094c1.675-.075 2.819-.344 3.825-.731 1.038-.4 1.913-.944 2.788-1.819s1.413-1.756 1.819-2.788c.387-1 .656-2.144.731-3.825s.094-2.212.094-6.494-.019-4.813-.094-6.494c-.075-1.675-.344-2.819-.731-3.825-.4-1.038-.944-1.913-1.819-2.788s-1.756-1.413-2.788-1.819c-1-.387-2.144-.656-3.825-.731C20.812.275 20.275.256 16 .256z"
               ></path>
               <path
                  fill="#444"
                  d="M16 7.912a8.088 8.088 0 0 0 0 16.175c4.463 0 8.087-3.625 8.087-8.088s-3.625-8.088-8.088-8.088zm0 13.338a5.25 5.25 0 1 1 0-10.5 5.25 5.25 0 1 1 0 10.5zM26.294 7.594a1.887 1.887 0 1 1-3.774.002 1.887 1.887 0 0 1 3.774-.003z"
               ></path>
            </svg>
         </a>
      </li>
      <li>
         <a
            href="https://www.tiktok.com/@braselet.com.ua?_t=8f5JKfk7axa"
            target="_blank"
         >
            <svg
               aria-hidden="true"
               focusable="false"
               role="presentation"
               viewBox="0 0 2859 3333"
            >
               <path
                  d="M2081 0c55 473 319 755 778 785v532c-266 26-499-61-770-225v995c0 1264-1378 1659-1932 753-356-583-138-1606 1004-1647v561c-87 14-180 36-265 65-254 86-398 247-358 531 77 544 1075 705 992-358V1h551z"
               ></path>
            </svg>
         </a>
      </li>
   </ul>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
   name: "SocialIcons",
   components: {},
   props: {},
   data() {
      return {};
   },
   computed: {
      ...mapGetters([
         // "NAME",
         // "NAME"
      ]),
   },
   methods: {
      ...mapActions([
         // "NAME",
         // "NAME"
      ]),
   },
   watch: {},
   created() {},
   beforeMount() {},
   mounted() {},
   beforeUnmount() {},
   unmounted() {},
   beforeUpdate() {},
   updated() {},
};
</script>

<style lang="scss">
.social {
   display: flex;
   align-items: center;
   column-gap: 4px;
   a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 3px;
      @media (min-width: 990px) {
         transition: all 0.5s ease;
         &:hover {
            transform: scale(1.15);
         }
      }
   }
   svg {
      width: 100%;
      max-width: 16px;
      max-height: 16px;
   }
   path {
      fill: $white;
   }
}
</style>
