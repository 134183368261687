<template>
   <button class="button">
      <slot></slot>
   </button>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
   name: "BlackButton",
   components: {},
   props: {},
   data() {
      return {};
   },
   computed: {
      ...mapGetters([
         // "NAME",
         // "NAME"
      ]),
   },
   methods: {
      ...mapActions([
         // "NAME",
         // "NAME"
      ]),
   },
   watch: {},
   created() {},
   beforeMount() {},
   mounted() {},
   beforeUnmount() {},
   unmounted() {},
   beforeUpdate() {},
   updated() {},
};
</script>

<style lang="scss">
.button {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 10px 20px;
   background-color: $dark;
   color: $white;
   min-width: 120px;
   min-height: 40px;
   transition: all 0.3s ease;
   @media (max-width: 989px) {
      @include rem(14);
   }
   @media (min-width: 990px) {
      @include rem(16);
   }
   &:hover {
      opacity: 0.9;
      // transform: scale(1.05);
   }
   &:focus {
      opacity: 0.9;
      // transform: scale(0.98);
      outline: none;
   }
   &:active {
      transform: scale(0.98);
      opacity: 0.8;
   }
}
</style>
