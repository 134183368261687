let url = "braselet.com.ua";
let email = "watchbandsstore.ua";
export default {
   trademark: "Офіційно зареєстврована торгова марка",
   lang: "Українська",
   from: "від",
   currency: "грн",
   empty: "Немає товарів по обраному фільтру...",
   test: "Вставляю замість i18nValue JS код: {i18nValue}",
   button: {
      view: "Детальніше",
      add: "Додати до кошика",
      order: "Швидке замовлення",
   },
   navigation: {
      lang: "Eng",
      home: "Головна",
      products: "Всі товари",
      story: "Наша історія",
      collections: "Колекції",
      contacts: "Зв'яжіться з нами!",
      faq: "FAQ",
      guarantee: "Гарантія",
   },
   footer: {
      menu: "Меню",
      subscribe: "Приєднуйтесь до нас!",
   },
   categories: {
      heading: "Колекція браслетів",
      shop: "До магазину",
      button: "До категорії",
      collars: "Заголовок для ошийників",
      carbines: "Заголовок для карабінів",
      straps: "Заголовок для ремінців",
      watches: "Заголовок для годинників",
   },
   products: {
      carbines: {
         title: "Заголовок карабінів",
         text: "Текст карабінів",
      },
      watches: {
         title: "Заголовок годинників",
         text: "Текст годинників",
      },
      collars: {
         title: "Заголовок ошийників",
         text: "Текст ошийників",
      },
      straps: {
         title: "Заголовок ремінців",
         text: "Текст ремінців",
      },
   },
   collections: {
      text: {
         military: {
            title: "Заголовок армійської колекції",
            text: "Текст армійської колекції",
         },
         patriotic: {
            title: "Заголовок патріотичної колекції",
            text: "Текст патріотичної колекції",
         },
         exclusive: {
            title: "Заголовок колекції ексклюзивних замовлень",
            text: "Текст колекції ексклюзивних замовлень",
         },
      },
      button: "До колекції",
      military: "Заголовок армійської колекції",
      patriotic: "Заголовок патріотичної колекції",
      exclusive: "Заголовок колекції ексклюзивних замовлень",
   },
   recommendations: {
      title: "Популярні товари",
   },
   filter: {
      categories: "Категорії",
      collections: "Колекції",
   },
   sort: {
      title: "Сортувати",
      toCheaper: "Від дорожчих до дешевших",
      fromCheaper: "Від дешевших до дорожчих ",
      toOlder: "Від новіших до старіших",
      fromOlder: "Від старіших до новіших",
   },
   faq: {
      title: "Часто вживані питання",
      q: "З",
      a: "В",
      questions: [
         "Як правильно дізнатися розмір свого зап'ястя?",
         "Як зробити індивідуальне замовлення?",
         "Запитання #3",
         "Запитання #4",
         "Запитання #5",
         //
      ],
      answers: [
         "Відповідь на Як правильно дізнатися розмір свого зап'ястя?",
         "Відповідь на Як зробити індивідуальне замовлення?",
         "Відповідь #3",
         "Відповідь #4",
         "Відповідь #5",
         //
      ],
   },
   guarantee: {
      title: "Гарантія та повернення",
      subtitle: [
         "Ми віримо в вироби, які ми створюємо, і знаємо, що задоволення клієнтів на першому місці.",
         "Тому ми гарантуємо, що:",
      ],
      list: [
         "Якщо виріб поламався або пошкодився через недоліки у нашій роботі протягом шести місяців з моменту покупки, ми замінимо його або повернемо вам гроші. Достатньо фотографії поламаного продукту і номеру замовлення.",
         "Якщо ви розпутали і використали один з наших виробів для виживання у справжніх екстримальних умовах і поділіться з нами своєю історією разом з фотографіями, ми надішлемо вам новий виріб на заміну. Вам потрібно буде сплатити лише вартість доставки/упаковки.",
         "Якщо розмір браслета не підходить, дайте нам знати та поверніть його невикористаним зареєстрованою поштою, і ми відкоригуємо його. Вам доведеться тільки покрити вартість доставки.",
         "Задоволеність гарантована. Якщо з будь-якої іншої причини ви не задоволені виробами, які ви купили у нас, просто зверніться до нас, і ми обіцяємо зробити все можливе, щоб ви були задоволені.",
      ],
   },

   cart: {
      title: "Кошик",
      subtotal: "Сумма",
      checkout: "Оформити",
      empty: "Ваш кошик зараз порожній.",
   },
   contacts: {
      title: "Зв'яжіться з нами",
      title_gallery: "Оформити індивідуальне замовлення",
      subtitle: "Або зателефонуйте нам за номером",
      form: {
         name: "Ім'я",
         email: "Імейл",
         message: "Повідомлення",
         button: "Надіслати",
      },
      subject: "Зворотній зв'язок з сайту bracelet.com.ua",
      done: "Лист надіслано.",
   },
   info: {
      cm: "см",
      size: "Точний розмір зап'ястя",
   },
   ordering: {
      method: {
         international: "Міжнародна доставка",
         ua: "Достака по Україні",
         toAddress: "До дверей",
         toWarehouse: "До відділення",
      },
      subject: "Замовлення на сайті braselet.com.ua успішно оформлене",
      order: "Замовлення",
      done: "На Ваш імейл відправлено листа. Номер замовлення - ",
      pay1: 'Сплатити за допомогую "WayForPay" (Скоро)',
      pay2: "Сплатити на пошті",
      paymentMethod: {
         title: "Статус оплати",
         1: "Сплачено",
         2: "Оплата на пошті",
      },
      title: "Адреса доставки",
      firstName: "Ім'я",
      lastName: "Прізвище",
      email: "Імейл",
      phone: "Телефон",
      country: "Країна",
      city: "Місто",
      postal: "Поштовий індекс",
      address: "Адреса",
      warehouse: "Відділення",
      message: "Додаткова інформація",
      confirm_wfp: "Сплатити і оформити замовлення",
      confirm_cod: "Оформити замовлення",
      customer: "Замовник",
      size: "Розмір",
      ordered: "Замовлені товари",
      info: "Інформація",
      shipping: {
         title: "Доставка",
         ua: "Вартість згідно тарифів перевізника",
         international: "Вартість залежить від місця призначення",
      },
      total: "Сума",
   },
   policy: {
      title: "Політика конфіденційності",
      subtitle: [
         `Політика щодо обробки персональних даних сайту ${url}`,
         `Збір та використання персональних даних`,
         `Зберігання, зміна та видалення даних`,
         `Використання технічних даних під час відвідування сайту`,
         `Надання інформації дітьми`,
         `Використання LocalStorage`,
         `Використання персональних даних іншими сервісами`,
         `Посилання на інші сайти`,
         `Зміни у політиці конфіденційності`,
         `Зворотній зв'язок, заключні положення`,
         ``,
         // ""
      ],
      text: [
         `Адміністрація сайту ${url} зобов'язується зберігати вашу конфіденційність в Інтернеті. Ми приділяємо велике значення охороні наданих вами даних. Наша політика конфіденційності ґрунтується на вимогах Загального регламенту захисту персональних даних Європейського Союзу (GDPR). З якими цілями ми збираємо персональні данні: покращення роботи нашого сервісу, здійснення контакту з відвідувачами даного сайту, надання інформації, яку запитував користувач, а також для наведених нижче дій.`,

         `Ми збираємо та використовуємо ваші персональні дані лише у випадку вашої добровільної згоди. За згодою з цим ви дозволяєте нам збирати та використовувати такі дані: ім'я та прізвище, електронна пошта, номер телефону. Збір та обробка ваших даних проводиться відповідно до законів, що діють на території Європейського Союзу та в Україні.`,

         `Користувач, який надав свої персональні дані сайту ${url}, має право на їх зміну та видалення, а також на відкликання своєї згоди з їх використанням. Термін, протягом якого зберігатимуться ваші персональні дані: час, необхідний для використання даних для основної діяльності сайту. При завершенні використання даних адміністрація сайту видаляє їх. Для доступу до ваших персональних даних ви можете зв'язатися з адміністрацією сайту ${url}. Ми можемо передавати ваші особисті дані третій стороні тільки з вашої добровільної згоди, якщо вони були передані, то зміни даних в інших організаціях, не пов'язаних з нами, не здійснюється.`,

         `При відвідуванні вами сайту ${url} в базі даних зберігаються записи про вашу IP адресу, час відвідування, налаштування браузера, операційну систему, а також інша технічна інформація необхідна для коректного відображення вмісту сайту. За цими даними нам неможливо ідентифікувати особу відвідувача.`,

         `Якщо ви є батьком або опікуном, і ви знаєте, що ваші діти надали нам свої особисті дані без вашої згоди, зв'яжіться з нами: ${email}{dog}gmail.com. На нашому сервісі заборонено залишати особисті дані неповнолітніх без згоди батьків чи опікунів.`,

         `Для коректного відображення вмісту та зручності використання сайту ${url} ми використовуємо LocalStorage файли. Це невеликі файли, які зберігаються на вашому пристрої. Вони допомагають сайту запам'ятати інформацію про вас, наприклад, якою мовою ви переглядаєте сайт і які сторінки ви вже відкривали, ця інформація буде корисна при наступному відвідуванні. Завдяки файлам LocalStorage перегляд сайту стає значно зручнішим. Ви можете налаштувати прийом або блокування LocalStorage у браузері самостійно. Неможливість зберігати дані у LocalStorage може обмежити працездатність сайту.`,

         `На цьому сайті використовуються сторонні інтернет-сервіси, які здійснюють незалежний від нас збір інформації. Зібрані ними дані можуть надаватися іншим службам усередині цих організацій, вони можуть використовувати дані для персоналізації реклами своєї рекламної мережі. Ви можете прочитати угоди цих організацій на їхніх сайтах. Там же ви можете відмовитися від збору ними персональних даних. Ми не передаємо персональні дані іншим організаціям та службам, не зазначеним у цій політиці конфіденційності. Виняток становить лише передача інформації за законних вимог державних органів уповноважених здійснювати дані дії.`,

         `Сайт ${url} може містити посилання на інші сайти, які не керуються нами. Ми не відповідаємо за їх зміст. Ми рекомендуємо вам ознайомитися з політикою конфіденційності кожного сайту, який ви відвідуєте, якщо вона є.`,

         `Наш сайт ${url} може оновлювати нашу політику конфіденційності час від часу. Ми повідомляємо про будь-які зміни, розмістивши нову політику конфіденційності на цій сторінці. Ми відстежуємо зміни законодавства щодо персональних даних у Європейському Союзі та в державі Україна.`,

         `Зв'язатися з адміністрацією сайту ${url} з питань, пов'язаних із політикою конфіденційності, можна за адресою: ${email}{dog}gmail.com, або за допомогою контактної форми, зазначеної у відповідному розділі даного сайту. Якщо ви не погоджуєтесь з даною політикою конфіденційності, ви не можете користуватися послугами сайту ${url}, у цьому випадку ви повинні утриматися від відвідування нашого сайту.`,

         // ""
      ],
      list: [
         "dsadasdas",
         // ""
      ],
   },
   terms: {
      // title: `Правила і умови інтернет-магазину ${url}`,
      title: `Порядок і терміни`,
      subtitle: [
         `Порядок і терміни надання товару`,
         `Способи оплати`,
         `Онлайн оплата через систему Way for Pay`,
         `Умови доставки`,
         // ""
      ],
      text: [
         `Після отримання Вашого замовлення, ми розпочинаємо процес упаковки та доставки. Всі замовлення обробляються та відправляються протягом 1-3 робочих днів.`,
         `Накладений платіж: При виборі цього способу оплати, Ви матимете можливість сплатити вартість товару та доставку безпосередньо при отриманні в відділенні Нової пошти.`,
         `Ви також можете використовувати Way for Pay для онлайн-оплати замовлення. Way for Pay дозволяє використовувати різні методи оплати, включаючи банківські картки і інтернет-банкінг. Оплата через Way for Pay захищена передовими методами шифрування.`,
         `Для доставки товару ми користуємося послугами Нової пошти. Вартість доставки розраховується індивідуально для кожного замовлення і залежить від ваги та розмірів пакунка, а також від відстані доставки. Після відправки товару ми надаємо Вам номер накладної, за допомогою якого Ви можете відстежувати стан Вашого замовлення.`,
         // ""
      ],
   },
   refund: {
      title: "Політика повернення грошових коштів",
      subtitle: [
         ``,
         ``,
         ``,
         ``,
         ``,
         // ""
      ],
      text: [
         `Якщо ви незадоволені своїм замовленням, ви маєте право на повернення товару протягом 14 днів з дня отримання товару, згідно з Законом України "Про захист прав споживачів". Зверніть увагу, що витрати на повернення товару не компенсуються.`,
         `Щоб отримати повернення, товар має бути у незмінному стані, тобто не бути відкритим, не мати ознак використання та зберігати свою торговельну й споживчу цінність.`,
         `У разі повернення товару, ми проводимо повернення грошей протягом 14 днів з дати, коли ми отримали товар назад. Кошти повертаються таким самим способом, яким були оплачені товари.`,
         ` Якщо ви виявили дефект товару, ви маєте право подати претензію. Для цього вам необхідно зв'язатися з нами протягом 2-х місяців з моменту виявлення дефекту, але не пізніше 2-х років з дати придбання товару.`,
         ` Всі претензії будуть розглянуті відповідно до Закону України "Про захист прав споживачів".`,
         // ""
      ],
   },
   contacts_info: {
      title: "Контактна інформація",
      subtitle: [
         ``,
         ``,
         ``,
         ``,
         ``,
         // ""
      ],
      text: [
         `ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ "ТОВ"`,
         `Код ЄДРПОУ 12312312321`,
         `04123, ХАРКІВСЬКА ОБЛ., ХАРКІВ, ВУЛ.КЛОЧКІВСЬКА, БУДИНОК 00 (юридична адреса співпадає з фактичною)`,
         `${email}{dog}gmail.com`,
         `+380 (00) 000 00 00`,
         // ""
      ],
   },
   nav_list: {
      refund: "Повернення грошових коштів",
      terms: "Правила та умови",
      policy: "Політика конфіденційності",
      contacts: "Контакти",
   },
   errors: {
      phone: `Введіть номер у вказаному форматі:{break} +380631234567`,
      phone_: `Будь ласка, введіть номер у коректному форматі для "{region}", без розділових знаків.`,
      email: `Введіть імейл у вказаному форматі:{break} name{dog}email.com`,
   },
};
