let url = "braselet.com.ua";
let email = "watchbandsstore.ua";
export default {
   trademark: "Officially registered trademark",
   lang: "English",
   from: "from",
   currency: "uah",
   empty: "There are no products for current filter...",
   test: "Insert instead i18nValue JS code: {i18nValue}",
   button: {
      view: "Quick view",
      add: "Add to cart",
      order: "Fast order",
   },
   navigation: {
      lang: "Укр",
      home: "Home",
      products: "All products",
      story: "Our story",
      collections: "Collections",
      contacts: "Contact us!",
      faq: "FAQ",
      guarantee: "Guarantee",
   },
   footer: {
      menu: "Menu",
      subscribe: "Join us!",
   },
   categories: {
      heading: "Bracelets collection",
      shop: "To shop",
      button: "To category",
      collars: "Collars title",
      carbines: "Carbines title",
      straps: "Straps title",
      watches: "Watches title",
   },
   products: {
      carbines: {
         title: "Title carbines",
         text: "Text carbines",
      },
      watches: {
         title: "Title watches",
         text: "Text watches",
      },
      collars: {
         title: "Title collars",
         text: "Text collars",
      },
      straps: {
         title: "Title straps",
         text: "Text straps",
      },
   },
   collections: {
      text: {
         military: {
            title: "Title military collection",
            text: "Text military collection",
         },
         patriotic: {
            title: "Title patriotic collection",
            text: "Text patriotic collection",
         },
         exclusive: {
            title: "Title exclusive collection",
            text: "Text exclusive collection",
         },
      },
      button: "To collection",
      military: "Army collection heading",
      patriotic: "Patriotic collection heading",
      exclusive: "Exclusive collection heading",
   },
   recommendations: {
      title: "Featured products",
   },
   filter: {
      categories: "Categories",
      collections: "Collections",
   },
   sort: {
      title: "Sort",
      toCheaper: "From more expensive to cheaper",
      fromCheaper: "From cheap to more expensive",
      toOlder: "From newer to older",
      fromOlder: "From older to newer",
   },
   faq: {
      title: "FAQS",
      q: "Q",
      a: "A",
      questions: [
         "Question #1",
         "Question #2",
         "Question #3",
         "Question #4",
         "Question #5",
         //
      ],
      answers: [
         "Answer #1",
         "Answer #2",
         "Answer #3",
         "Answer #4",
         "Answer #5",
         //
      ],
   },

   guarantee: {
      title: "Warranty and Returns",
      subtitle: [
         "We believe in the products we create and understand that customer satisfaction comes first.",
         "That's why we guarantee that:",
      ],
      list: [
         "If a product breaks or is damaged due to defects in our work within six months from the date of purchase, we will replace it or refund your money. A photo of the broken product and the order number are sufficient.",
         "If you've unraveled and used one of our products for survival in genuine extreme conditions and share your story with us along with photos, we will send you a new product as a replacement. You'll only need to cover the cost of shipping/packaging.",
         "If the size of a bracelet is not suitable, let us know and return it unused via registered mail, and we will adjust it for you. You'll only need to cover the shipping cost.",
         "Satisfaction is guaranteed. If, for any other reason, you're not satisfied with the products you've purchased from us, simply reach out to us, and we promise to do everything possible to ensure your satisfaction.",
      ],
   },
   cart: {
      title: "Cart",
      subtotal: "Subtotal",
      checkout: "Checkout",
      empty: "Your cart is currently empty.",
   },

   contacts: {
      title: "Contact us",
      title_gallery: "Place an individual order",
      subtitle: "Or call us at the number",
      form: {
         name: "Name",
         email: "Email",
         message: "Message",
         button: "Send",
      },
      subject: "Feedback from bracelet.com.ua",
      done: "The letter has been sent.",
   },
   info: {
      cm: "cm",
      size: "Exact Wrist Size",
   },
   ordering: {
      method: {
         international: "International delivery",
         ua: "Delivery in Ukraine",
         toAddress: "To home",
         toWarehouse: "To the post office",
      },
      done: "A letter has been sent to your email. Order number - ",
      subject: "The order on the bracelet.com.ua has been successfully placed",
      order: "Order",
      pay1: 'Pay with "WayForPay" (Available soon)',
      pay2: "C.O.D.",
      paymentMethod: {
         title: "Статус оплати",
         1: "Payed",
         2: "C.O.D.",
      },
      title: "Shipping address",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      phone: "Phone",
      country: "Country",
      city: "City",
      postal: "Postal code",
      address: "Address",
      warehouse: "Warehouse",
      message: "Additional information",
      confirm_wfp: "Pay and complete the order",
      confirm_cod: "Make an order",
      customer: "Customer",
      size: "Size",
      ordered: "Order",
      info: "Information",
      shipping: {
         title: "Shipping",
         ua: "The cost is according to the carrier's tariffs",
         international: "The price depends on the destination",
      },
      total: "Total",
   },
   policy: {
      title: "Privacy Policy",
      subtitle: [
         `Policy regarding the processing of personal data of the ${url} website`,
         `Collection and use of personal data`,
         `Data storage, modification and deletion`,
         `Use of technical data when visiting the site`,
         `Provision of information by children`,
         `Use of LocalStorage`,
         `Use of personal data by other services`,
         `Links to other sites`,
         `Changes to the privacy policy`,
         `Feedback, final provisions`,
         ``,
         // ""
      ],
      text: [
         `The administration of the ${url} site undertakes to keep your online privacy. We attach great importance to the protection of the provided your data. Our privacy policy is based on the requirements of the General the regulation of personal data protection of the European Union (GDPR). For what purposes we collect personal data: improving our work service, making contact with visitors to this site, providing information, requested by the user, as well as for the following actions.`,

         `We collect and use your personal data only in the case of you voluntary consent. By agreeing to this, you allow us to collect and use the following data: first and last name, e-mail, phone number. Collection and processing of your data is carried out in accordance with the laws in force in the territory of the European Union and in Ukraine.`,

         `The user who provided his personal data to the ${url} website, has the right to modify and delete them, as well as to withdraw his consent to them using The period during which your personal data will be stored: the time required to use the data for the main activity of the site. At the end of using the data, the site administration deletes them. To access your personal data, you can contact the administration of the ${url} website. We may transfer your personal data to a third party only on your behalf voluntary consent, if they were transferred, then data changes in others organizations not affiliated with us are not carried out.`,

         `When you visit the ${url} website, they are stored in the database records of your IP address, time of visit, browser settings, operating system, as well as other technical information necessary for correct display of site content. According to these data, it is impossible for us to identify the identity of the visitor.`,

         `If you are a parent or guardian and you know that your children have provided us with their personal data without your consent, contact us: ${email}{dog}gmail.com. It is forbidden to leave personal data of minors on our service without consent parents or guardians.`,

         `For proper content display and enhanced usability of the website ${url}, we use LocalStorage files. These are small files that are stored on your device. They assist the website in remembering information about you, such as the language you are viewing the site in and the pages you have previously opened. This information will be helpful for your next visit. Thanks to LocalStorage files, browsing the website becomes much more convenient. You can configure acceptance or blocking of LocalStorage in your browser on your own. Inability to store data in LocalStorage may limit the functionality of the website.`,

         `This site uses third-party Internet services that provide collection of information independent of us. The data they collect may be shared other services within these organizations, they may use the data to personalize your ad network ads. you can read agreements of these organizations on their websites. There you can opt out their collection of personal data. We do not share personal data with others organizations and services not specified in this privacy policy. The only exception is the transfer of information under the legal requirements of the state bodies authorized to carry out these actions.`,

         `The site ${url} may contain links to other sites, which are not controlled by us. We are not responsible for their content. We recommend you read the privacy policy of each site you visit, if there is one.`,

         `Our site ${url} may update our privacy policy from time to time. We will notify you of any changes by posting the new policy privacy policy on this page. We monitor changes in legislation regarding of personal data in the European Union and Ukraine.`,

         `Contact the administration of the ${url} site on issues related to privacy policy, you can at the address: ${email}{dog}gmail.com. If you do not agree to this privacy policy, you may not to use the services of the ${url} website, in this case you must refrain from visiting our site.`,

         // ""
      ],
      list: [
         "dasdasdas",
         // ""
      ],
   },
   terms: {
      title: `Terms and conditions`,
      subtitle: [
         `Procedure and terms of delivery of goods`,
         `Payment methods`,
         `Online payment through the Way for Pay system`,
         `Terms of delivery`,
         // ""
      ],
      text: [
         `After receiving your order, we confirm it and start the packaging and delivery process. All orders received before 12pm are usually processed and shipped within 1-3 business days.`,
         `Cash on delivery: When choosing the "C.O.D." option, you will have the opportunity to pay the cost of the product and delivery directly upon receipt at the "New Post" branch. Please note that when choosing this payment method, Nova Poshta may charge an additional fee for its services.`,
         `You can also use Way for Pay to pay for your order online. Way for Pay allows you to use various payment methods, including bank cards and internet banking. Payment through Way for Pay is protected by advanced encryption methods.`,
         `For the delivery of goods, we use the services of "Nova Poshta". The cost of delivery is calculated individually for each order and depends on the weight and dimensions of the package, as well as on the delivery distance. After sending the goods, we provide you with the invoice number, with which you can track the status of your order.`,
         // ""
      ],
   },
   refund: {
      title: "Refund policy",
      subtitle: [
         ``,
         ``,
         ``,
         ``,
         ``,
         // ""
      ],
      text: [
         `If you are not satisfied with your order, you have the right to return the product within 14 days from the day of receipt of the product, in accordance with the Law of Ukraine "On the Protection of Consumer Rights". Please note that the costs of returning the product are not compensated.`,
         `In order to receive a return, the product must be returned in its original condition, ie unopened, showing no signs of use and retaining its retail and consumer value.`,
         `In the event of a product return, we issue a refund within 14 days from the date we received the product back. Money is returned in the same way as the goods were paid for.`,
         `If you discover a product defect, you have the right to file a claim. To do this, you need to contact us within 2 months from the moment of discovery of the defect, but no later than 2 years from the date of purchase of the product.`,
         `All claims will be considered in accordance with the Law of Ukraine "On the Protection of Consumer Rights".`,
         // ""
      ],
   },
   contacts_info: {
      title: "Contact information",
      subtitle: [
         ``,
         ``,
         ``,
         ``,
         ``,
         // ""
      ],
      text: [
         `LIMITED LIABILITY COMPANY "ТОВ"`,
         `EDRPOU Code 12312312321`,
         `04123, KHARKIV REGION, KHARKIV, KLOCHKIVSKA STREET, BUILDING 00 (legal address is the same as the actual address)`,
         `${email}{dog}gmail.com`,
         `+380 (00) 000 00 00`,
         // ""
      ],
   },
   nav_list: {
      refund: "Refund policy",
      terms: "Terms and conditions",
      policy: "Privacy Policy",
      contacts: "Contacts",
   },
   errors: {
      phone: `Enter the number in the specified format:{break} +380631234567 (for Ukraine)`,
      phone_: `Please enter the number in the correct format for "{region}", without separators.`,
      email: `Enter the email in the specified format:{break} name{dog}email.com`,
   },
};
