<template>
   <footer class="footer">
      <div class="footer__container">
         <div class="footer__top footer-top">
            <router-link
               :to="{ name: 'home' }"
               class="footer-top__logo"
            >
               <img :src="require(`../../assets/img/webp/logo.webp`)" />
            </router-link>
            <div class="footer-top__social">
               <h6 class="footer-top__social-title">
                  {{ $t("footer.subscribe") }}
               </h6>

               <SocialIconsVue />
            </div>
            <div
               class="footer-top__spoiler"
               ref="spoiler"
            >
               <div
                  class="footer-top__spoiler-preview"
                  @click="toggleSpoiler"
               >
                  <div class="footer-top__spoiler-title">{{ $t("footer.menu") }}</div>
                  <div class="footer-top__spoiler-icon">
                     <svg
                        aria-hidden="true"
                        viewBox="0 0 28 16"
                     >
                        <path
                           d="M1.57 1.59l12.76 12.77L27.1 1.59"
                           stroke-width="2"
                           stroke="#fff"
                           fill="none"
                           fill-rule="evenodd"
                        ></path>
                     </svg>
                  </div>
               </div>
               <nav
                  class="footer-top__menu"
                  ref="spoiler-content"
               >
                  <ul
                     class="footer-top__list"
                     @click="closeSpoiler"
                  >
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'home' }">
                           {{ $t("navigation.home") }}
                        </router-link>
                     </li>
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'products' }">
                           {{ $t("navigation.products") }}
                        </router-link>
                     </li>
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'collections' }">
                           {{ $t("navigation.collections") }}
                        </router-link>
                     </li>
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'faq' }">
                           {{ $t("navigation.faq") }}
                        </router-link>
                     </li>
                     <!-- <li class="footer-top__list-item">
                        <router-link :to="{ name: 'about' }">
                           {{ $t("navigation.story") }}
                        </router-link>
                     </li> -->
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'feedback' }">
                           {{ $t("navigation.contacts") }}
                        </router-link>
                     </li>
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'guarantee' }">
                           {{ $t("navigation.guarantee") }}
                        </router-link>
                     </li>
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'policy' }">
                           {{ $t("nav_list.policy") }}
                        </router-link>
                     </li>
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'refund' }">
                           {{ $t("nav_list.refund") }}
                        </router-link>
                     </li>
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'terms' }">
                           {{ $t("nav_list.terms") }}
                        </router-link>
                     </li>
                     <li class="footer-top__list-item">
                        <router-link :to="{ name: 'contacts' }">
                           {{ $t("nav_list.contacts") }}
                        </router-link>
                     </li>
                  </ul>
               </nav>
            </div>
         </div>
         <div class="footer__bottom footer-bottom">
            <!-- <div class="footer-bottom__lang">
               {{ $t("lang") }}
            </div> -->
            <div class="footer-bottom__copy">© 2023 Ремінець STORE</div>
         </div>
      </div>
   </footer>
</template>

<script>
import SocialIconsVue from "../UI/SocialIcons.vue";
export default {
   name: "BaseFooter",
   components: { SocialIconsVue },
   props: {},
   data() {
      return {};
   },
   computed: {},
   methods: {
      toggleSpoiler() {
         console.log(1);

         if (window.innerWidth < 990) {
            console.log(2);
            if (this.$refs.spoiler.classList.contains("opened")) {
               console.log(3);
               this.$refs.spoiler.classList.remove("opened");
               this.$refs["spoiler-content"].style.height = 0;
            } else {
               console.log(4);
               this.$refs.spoiler.classList.add("opened");
               this.$refs["spoiler-content"].style.height = this.$refs["spoiler-content"].scrollHeight - 30 + "px";
            }
         }
      },
      closeSpoiler() {
         this.$refs.spoiler.classList.remove("opened");
         if (window.innerWidth < 990) {
            this.$refs["spoiler-content"].style.height = 0;
         }
      },
   },
   watch: {
      $route() {
         this.closeSpoiler();
      },
   },
   created() {},
   beforeMount() {},
   mounted() {},
   beforeUnmount() {},
   unmounted() {},
   beforeUpdate() {},
   updated() {},
};
</script>

<style lang="scss">
.footer {
   background-color: $dark;
   color: $white;
   // .footer__container

   &__container {
      @extend %container;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: column;
      row-gap: 20px;
   }
}
.footer-top {
   display: flex;
   @media (max-width: 989px) {
      row-gap: 20px;
      flex-direction: column;
   }
   @media (min-width: 990px) {
      justify-content: space-between;
      justify-content: space-around;
      justify-content: space-evenly;
      column-gap: 20px;
   }
   // .footer-top__logo

   &__logo {
      display: inline-block;
      width: 100%;
      @media (max-width: 989px) {
         max-width: 70px;
         align-self: center;
      }
      @media (min-width: 990px) {
         max-width: 100px;
      }
   }

   // .footer-top__social

   &__social {
      @include fl(12);
      @extend %space2;
      text-transform: uppercase;
      @media (max-width: 989px) {
         display: flex;
         align-items: center;
         flex-direction: column;
         row-gap: 8px;
      }
      @media (min-width: 990px) {
         .social {
            margin-top: 12px;
            max-width: max-content;
            margin-left: auto;
         }
      }
      @media (max-width: 989px) {
         svg {
            max-width: 24px;
            max-height: 24px;
         }
      }
   }

   // .footer-top__spoiler

   &__spoiler {
      @media (max-width: 989px) {
         border-top: 1px solid rgba(255, 255, 255, 0.2);
         border-bottom: 1px solid rgba(255, 255, 255, 0.2);
         &.opened {
            svg {
               transform: scaleY(-1);
            }
            .footer-top__list {
               transform: translateY(0);
               opacity: 1;
            }
         }
      }
      @media (min-width: 990px) {
         display: flex;
         flex-direction: column;
         row-gap: 12px;
      }
   }
   // .footer-top__spoiler-preview

   &__spoiler-preview {
      @media (max-width: 989px) {
         position: relative;
         display: flex;
         justify-content: center;
         align-items: center;
         column-gap: 10px;
         padding-left: 20px;
         padding-right: 20px;
         padding: 20px;
      }
   }

   // .footer-top__spoiler-title

   &__spoiler-title {
      text-transform: uppercase;
      @extend %space2;
      @media (max-width: 989px) {
         @include rem(12);
         text-align: center;
      }
      @media (min-width: 990px) {
         @include rem(13);
      }
   }
   // .footer-top__spoiler-icon

   &__spoiler-icon {
      @media (max-width: 989px) {
         display: flex;
         justify-content: center;
         align-items: center;
         position: absolute;
         top: 50%;
         right: 0;
         transform: translateY(-50%);

         svg {
            transition: transform 0.3s ease;
            width: 12px;
            height: auto;
         }
      }
      @media (min-width: 990px) {
         display: none;
      }
   }

   // .footer-top__menu

   &__menu {
      @media (max-width: 989px) {
         height: 0;
         overflow: hidden;
         transition: height 0.7s ease;
      }
      @media (min-width: 990px) {
         height: auto;
      }
   }

   // .footer-top__list

   &__list {
      display: grid;
      @media (max-width: 989px) {
         padding-bottom: 20px;
         transition: transform 0.7s ease 0.1s, opacity 1s ease 0.1s;
         opacity: 0;
         transform: translateY(30px);
         overflow: hidden;
         row-gap: 5px;
      }
      @media (min-width: 990px) {
         margin-left: -10px;
         grid-template-columns: repeat(3, auto);
         column-gap: 20px;
         row-gap: 5px;
      }
   }

   // .footer-top__list-item

   &__list-item {
      @media (max-width: 989px) {
         text-align: center;
      }
      a {
         display: inline-block;
         padding: 5px 10px;
         @media (max-width: 989px) {
            @include rem(11);
         }
         @media (min-width: 990px) {
            @include rem(12);
            position: relative;
            &:hover {
               &:after {
                  width: calc(100% - 16px);
               }
            }
            &:after {
               transition: all 0.5s ease;
               content: "";
               position: absolute;
               bottom: 0;
               left: 8px;
               right: 8px;
               width: 0;
               height: 1.5px;
               background: $white;
            }
         }
      }
   }
}
.footer-bottom {
   text-align: center;
   display: flex;
   flex-direction: column;

   row-gap: 8px;
   // .footer-bottom__lang

   &__lang {
      @include rem(13);
   }

   // .footer-bottom__copy

   &__copy {
      @include rem(10);
   }
}
</style>
